export const homeObjOne = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'ABOUT',
    headline: 'XTECHDOT LIMITED',
    description: 'XTECHDOT Limited is an IT service provider, we provide premier consultancy on infrastructure solutions, such as networking, telephony solutions, Internet security and IT management solutions. We will provide you with the best IT solutions and consulting services.',
    description2: '"Use the most cost-effective way to achieve your goal"',
    imgStart: true,
    img: '/images/AI.jpg',
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: false
};

export const homeObjTwo = {
    id: 'contact',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'CONTACT',
    headline: 'Lets FIND US',
    description: 'Office hours: Weekday from 9am to 6pm.',
    description2: 'Email: info@xtech.hk',
    description3: 'Phone: (852) 5480 6202',
    imgStart: false,
    img: '/images/logo.png',
    alt: 'fds',
    dark: false,
    primary: false,
    darkText: true
};
