import React from 'react'
import { animateScroll as scroll} from 'react-scroll'
import { 
    FooterContainer, 
    FooterWrap, 
    SocialMedia,
    SocialMediaWrap,
    WebsiteRights
} from './FootElements'

const Footer = () => {
    const toggleHome = () => {
        scroll.scrollToTop()
    };

    return (
        <FooterContainer>
            <FooterWrap>
                <SocialMedia>
                    <SocialMediaWrap>
                        <WebsiteRights>XTECHDOT LIMITED © {new Date().getFullYear()} All rights reserved</WebsiteRights>
                    </SocialMediaWrap>
                </SocialMedia>
            </FooterWrap>
        </FooterContainer>
    )
}

export default Footer
