import React, {useState} from 'react';
import HeroBgImg123 from '../../images/server.jpg';
import { HeroContainer, HeroBg, HeroBgImg, HeroContent, HeroH1, HeroP } from './HeroElements';

const HeroSection = () => {
    const [hover, setHover] = useState(false)
    const onHover = () => {
        setHover(!hover)
    }

    return (
        <HeroContainer id="home">
            <HeroBg>
                <HeroBgImg src={HeroBgImg123}/>
            </HeroBg>
            <HeroContent>
                <HeroH1>XTECHDOT LIMITED</HeroH1>
                <HeroP>
                Make your life easier
                </HeroP>
            </HeroContent>
        </HeroContainer>
    )
}

export default HeroSection
