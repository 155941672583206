import React from 'react';
import Icon1 from '../../images/datacenter.jpg';
import Icon2 from '../../images/networking.jpg';
import Icon3 from '../../images/app.jpg';
import Icon4 from '../../images/programing.jpg';
import { ServicesContainer, ServicesH1, ServicesWrapper, ServicesCard, ServicesIcon, ServicesH2, ServicesP } from './ServicesElements';

const Services = () => {
    return (
        <ServicesContainer id="services">
            <ServicesH1>Our Services</ServicesH1>
            <ServicesWrapper>
                <ServicesCard>
                    <ServicesIcon src={Icon1} />
                    <ServicesH2>Cloud Setup</ServicesH2>
                    <ServicesP>- Platform: AWS, Azure, GCloud</ServicesP>
                    <ServicesP>- Methodology: Terraform, Kubernetes, AWS CloudFormation etc...</ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon2} />
                    <ServicesH2>Networking Setup</ServicesH2>
                    <ServicesP>- Firewall, Router, Wifi configuration</ServicesP>
                    <ServicesP>- Consultation & Implementation on ACL, VPN, VLAN etc...</ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon3} />
                    <ServicesH2>Mobile Apps Development</ServicesH2>
                    <ServicesP>E-Wallet, Resident Mgt App</ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon4} />
                    <ServicesH2>System Development</ServicesH2>
                    <ServicesP>- Payment System Integration Faster Payment System</ServicesP>
                    <ServicesP>- CRM System, API Development and Integration</ServicesP>
                </ServicesCard>
            </ServicesWrapper>
            
        </ServicesContainer>
    )
}

export default Services
